import React, {useState, useContext} from 'react'
import {useForm} from 'react-hook-form'
import ErrorAlert from 'components/ErrorAlert/ErrorAlert'
import {generateSubmitFormHandler} from 'utils/formSubmission'
import {ScrollToTopButton, Fieldset, GridContainer, TextArea, Input, InputFile, Select} from 'f-lib'
import AuthContext from 'context/auth.context'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import FormSuccess from 'components/FormSuccess/FormSuccess'
import SubmitterInformationFieldset from 'components/SubmitterInformationFieldset/SubmitterInformationFieldset'

import './TestForm.scss'
import {makeRequired, nameValidator, imageFileValidator} from 'utils/fieldValidators'

const staffRoles = {
  MANAGEMENT: 'management',
  SALES_MARKETING: 'salesmarketing',
  STAFF: 'staff',
}

const TestForm = () => {
  const {user} = useContext(AuthContext)
  const templateID = 'formhost-test' // template to be used by the Mailer Service
  const formName = templateID // ID to be used when parsing mailed form submissions
  const formURL = '/test-form' // URL of this form
  const formLabel = 'Test Form' // used as heading for the form page
  const formSubmittedMessage = 'Your data has been submitted securely.' // Submission message

  const [formDisabled, setFormDisabled] = useState(false)
  const [formError, setFormError] = useState()
  const [formSuccess, setFormSuccess] = useState(false)
  const {
    register: registerForm,
    handleSubmit: handleFormSubmit,
    errors: formErrors,
  } = useForm({criteriaMode: 'all'})

  const submitFormHandler = generateSubmitFormHandler({ // makes handler that accepts single formData param, while other needed things are in scope
    domain: 'Test',
    user,
    setFormDisabled,
    setFormError,
    setFormSuccess,
    formName,
    templateID,
    // confirmationTemplateID not being used since we want noi confirmation
    saveToMongo: true,
  }, (formData) => {
    formData.specialRequests = formData.specialRequests.replaceAll('\n', '  ***  ')

    return formData
  })

  return (
    <main>
      {formError && (
        <ErrorAlert error={formError}></ErrorAlert>
      )}
      <div className="container">
        <div className="formHeader">
          <h1>{formLabel}</h1>
          <FormSuccess show={formSuccess} message={formSubmittedMessage} createAnotherURL={formURL}/>
        </div>
        {!formSuccess &&
        <>
          <form
            className="form"
            onSubmit={handleFormSubmit(submitFormHandler)}
          >
            <SubmitButton disabled={formDisabled}/>

            <Fieldset numberOfColumns={4} label={'Test Form Stuff'}>
              {/* row 1 */}
              <Input name="firstName" label="First Name" validators={makeRequired(nameValidator)}
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>
              <Input name="lastName" label="Last Name" validators={makeRequired(nameValidator)}
                col="3" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>

              {/* row 2 */}
              <InputFile name="cardFront" label="Front of Card" validators={makeRequired(imageFileValidator)}
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>
              <InputFile name="cardBack" label="Back of Card" validators={imageFileValidator}
                col="3" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>

              {/* row 3 */}
              <Input name="lanID" label="Network User ID"
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>
              <Input name="officeCode" label="Office Code"
                col="3" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>

              {/* row 4 */}
              <Select name="staffRole" label="Office Staff Role"
                defaultValue="CAS" options={[
                  {
                    label: 'PML Field Staff',
                    value: staffRoles.STAFF,
                  },
                  {
                    label: 'PML Field Management',
                    value: staffRoles.MANAGEMENT,
                  },
                  {
                    label: 'Sales / Marketing',
                    value: staffRoles.SALES_MARKETING,
                  },
                ]}
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>
              <Select name="distributionChannel" label="Distribution Channel"
                defaultValue="CAS" options={[
                  {
                    label: 'CAS',
                    value: 'CAS',
                  },
                  {
                    label: 'IFN',
                    value: 'IFN',
                  },
                ]}
                col="3" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>

            </Fieldset>

            <Fieldset numberOfColumns={2} label={'Requested Services'}>
              {/* row 1 */}
              <GridContainer col={1} colSpan={2} label="Directions">
                <p>Multiple lines here will be turned into a single line with *** separating what had been separate lines.</p>
                <p>This is done by the formData mutating function passed to the form handler.</p>
              </GridContainer>

              {/* row 2 */}
              <TextArea label={'Extra Services'} name={'specialRequests'}
                col={1} colSpan={2} row={2}
                register={registerForm} formDisabled={formDisabled} errors={formErrors}/>

            </Fieldset>

            <SubmitterInformationFieldset registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors}
              userId={user.id} userFullName={user.fullName} userEmail={user.email}/>

            <SubmitButton disabled={formDisabled}/>
          </form>
        </>
        }

      </div>
      <ScrollToTopButton></ScrollToTopButton>
    </main>
  )
}

export default TestForm
