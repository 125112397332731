import React from 'react'
import {PageFooter} from 'f-lib'

const Footer = () => {
  return (
    <PageFooter>
      Copyright &copy;{new Date().getFullYear()} Penn Mutual. All Rights Reserved - FOR INTERNAL USE ONLY
    </PageFooter>
  )
}

export default Footer
