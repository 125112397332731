import React, {useState, useContext} from 'react'
import {useForm} from 'react-hook-form'
import ErrorAlert from 'components/ErrorAlert/ErrorAlert'
import {ScrollToTopButton, Fieldset, TextArea, If, Input, Select, GridContainer} from 'f-lib'
import AuthContext from 'context/auth.context'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import FormSuccess from 'components/FormSuccess/FormSuccess'
import SubmitterInformationFieldset from 'components/SubmitterInformationFieldset/SubmitterInformationFieldset'
import {generateSubmitFormHandler} from 'utils/formSubmission'
import {serviceHoursValidator, pastDateValidator, makeRequired} from 'utils/fieldValidators'

const departments = [
  {value: 'assurance', label: 'Assurance (Internal Audit)'},
  {value: 'businesstransformation', label: 'Business Transformation'},
  {value: 'enterpriseleadership', label: 'Enterprise Leadership'},
  {value: 'compliance', label: 'Ethics and Compliance'},
  {value: 'facilities', label: 'Facilities'},
  {value: 'cas', label: 'Field: CAS'},
  {value: 'ifn', label: 'Field: IFN'},
  {value: 'financialmanagement', label: 'Financial Management'},
  {value: 'htk', label: 'HTK'},
  {value: 'hr', label: 'Human Resources'},
  {value: 'im&t', label: 'IM&T'},
  {value: 'law', label: 'Law'},
  {value: 'marketing', label: 'Marketing and Communications'},
  {value: 'pmam', label: 'PMAM'},
  {value: 'prodmortfldsupp', label: 'Product, Underwriting, Mortality Management and Field Sales Support'},
  {value: 'riskmanagement', label: 'Risk Management'},
  {value: 'service', label: 'Service'},
]

const VolunteerHours = () => {
  const {user} = useContext(AuthContext)
  const templateID = 'volunteer-hours' // template to be used by the Mailer Service
  const confirmationTemplateID = `${templateID}-confirmation`
  const formName = templateID // ID to be used when parsing mailed form submissions
  const formURL = `/${templateID}` // URL of this form
  const formLabel = 'Volunteer Hours' // used as heading for the form page
  const formSubmittedMessage = 'Your form has been submitted securely.  You should receive a confirmation shortly.' // Submission message

  const [formDisabled, setFormDisabled] = useState(false)
  const [formError, setFormError] = useState()
  const [formSuccess, setFormSuccess] = useState(false)
  const {
    register: registerForm,
    handleSubmit: handleFormSubmit,
    errors: formErrors,
  } = useForm({criteriaMode: 'all'})

  const [onBehalfOf, setOnBehalfOf] = useState('no')
  const [department, setDepartment] = useState(departments[0].value)

  const mutateFormData = (formData) => { // function to do custom modifications on FormData inside the form handler
    if (formData.onBehalfOf === 'no') { // If submitting for self, user user's own values
      formData.volunteerName = user.fullName
      formData.volunteerEmail = user.email
    }

    formData.volunteerEmail = formData.volunteerEmail.toLowerCase()
    formData.serviceDate = new Date(formData.serviceDate)

    return formData
  }

  const submitFormHandler = generateSubmitFormHandler({ // makes handler that accepts single formData param, while other needed things are in scope
    domain: 'HumanResources',
    user,
    setFormDisabled,
    setFormError,
    setFormSuccess,
    formName,
    templateID,
    confirmationTemplateID,
    saveToMongo: true,
  }, mutateFormData)

  return (
    <main>
      {formError && (
        <ErrorAlert error={formError}></ErrorAlert>
      )}
      <div className="container">
        <div className="formHeader">
          <h1>{formLabel}</h1>
          <FormSuccess show={formSuccess} message={formSubmittedMessage} createAnotherURL={formURL} />
        </div>
        {!formSuccess &&
        <>
          <form
            className="form"
            onSubmit={handleFormSubmit(submitFormHandler)}
          >
            <SubmitButton disabled={formDisabled} />

            <SubmitterInformationFieldset registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors}
              userId={user.id} userFullName={user.fullName} userEmail={user.email} />

            <Fieldset numberOfColumns={2} label={'Volunteer Information'} >

              <Select name="department" label="Area of Company"
                defaultValue={departments[0].value}
                onChange={(event) => { setDepartment(event.target.value) }}
                options={departments}
                col="1" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              <If condition={department === 'cas' || department === 'ifn'}>
                <Input name="fieldOffice" label={department === 'cas' ? 'Field Office Name' : 'IFN Region'} validators={makeRequired()}
                  col={1} disabled={formDisabled} register={registerForm} errors={formErrors}/>
              </If>

              <Select name="onBehalfOf" label="Are you the volunteer?"
                onChange={(event) => { setOnBehalfOf(event.target.value) }} defaultValue="no"
                options={[
                  {
                    label: 'YES, I\'m submitting my own hours',
                    value: 'no',
                  },
                  {
                    label: 'NO, I\'m logging hours for another',
                    value: 'yes',
                  },
                ]}
                col="1" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              <If condition={onBehalfOf === 'yes'}>
                <Input name="volunteerName" label="Volunteer's Name" validators={makeRequired()}
                  col={1} disabled={formDisabled} register={registerForm} errors={formErrors}/>

                <Input name="volunteerEmail" label="Volunteer's Email Address" validators={makeRequired()}
                  col={1} colSpan={3} disabled={formDisabled} register={registerForm} errors={formErrors}/>
              </If>
            </Fieldset>

            <Fieldset numberOfColumns={2} label={'Volunteering Event Information'} >
              <Input name="organizationName" label="Volunteer Organization Name" validators={makeRequired()}
                disabled={formDisabled} register={registerForm} errors={formErrors} />

              <Input name="eventName" label="Volunteer Event Name (if any)"
                disabled={formDisabled} register={registerForm} errors={formErrors}/>

              <TextArea name="eventDescription" label="Brief Description of Service Volunteered" validators={makeRequired()}
                col="1" colSpan="2" textareaRows="3" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              <Input name="serviceDate" label="Date of Volunteer Service" type="date" validators={makeRequired(pastDateValidator)}
                col="1" disabled={formDisabled} register={registerForm} errors={formErrors}/>

              <Input name="serviceHours" type="number" label="Number of Service Hours" validators={makeRequired(serviceHoursValidator)}
                disabled={formDisabled} register={registerForm} errors={formErrors}/>

              <GridContainer col={1} colSpan={3} label="Event Outcomes/Highlights">
                <p>Please list any notable event outcomes below.  For example: number of meals prepared/people fed, number of miles of road cleaned up, how many books gathered for donation, etc.</p>
              </GridContainer>

              <TextArea name="eventOutcomes" hideLabel={true}
                col="1" colSpan="2" textareaRows="3" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              <Select name="pmlSponsoredEvent" label="Was this a PML Sponsored Event?"
                options={[
                  {
                    label: 'Yes, this was a Penn Mutual sponsored event',
                    value: 'yes',
                  },
                  {
                    label: 'No, this wasn\'t a Penn Mutual sponsored event',
                    value: 'no',
                  },
                ]}
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

            </Fieldset>

            <SubmitButton disabled={formDisabled} />
          </form>
        </>
        }

      </div>
      <ScrollToTopButton></ScrollToTopButton>
    </main>
  )
}

export default VolunteerHours
