import React from 'react'
import {Alert} from 'f-lib'
import get from 'lodash/get'

const ErrorAlert = ({error}) => {
  let errorContent

  if (typeof error === 'string') {
    errorContent = error
  }

  if (typeof error === 'object') {
    const data = get(error, 'data')
    const status = get(error, 'status')
    const statusText = get(error, 'statusText')

    errorContent = (
      <>
        <h2 className="errorAlert-header">{status}: {statusText}</h2>
        <ul>
          {data && Object.values(data).map((value, i) => (
            <li className="errorAlert-content" key={i}>
              {value}
            </li>
          ))}
        </ul>
      </>
    )
  }

  return (
    <Alert>
      {errorContent}
    </Alert>
  )
}

export default ErrorAlert
