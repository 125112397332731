import React, {useContext, useState} from 'react'
import ErrorAlert from 'components/ErrorAlert/ErrorAlert'
import FormSuccess from 'components/FormSuccess/FormSuccess'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import {Fieldset, Input, GridContainer, Checkbox, Select, TextArea} from 'f-lib'
import AuthContext from 'context/auth.context'
import {useForm} from 'react-hook-form'
import {makeRequired} from 'utils/fieldValidators'
import SubmitterInformationFieldset from 'components/SubmitterInformationFieldset/SubmitterInformationFieldset'
import {generateSubmitFormHandler} from 'utils/formSubmission'

const locations = {
  HORSHAM: 'horsham',
  WINDSOR: 'windsor',
  FIELD: 'field',
}

const EquipmentCatalog = () => {
  const {user} = useContext(AuthContext)
  const templateID = 'equipment-catalog' // template to be used by the Mailer Service
  const confirmationTemplateID = `${templateID}-confirmation`
  const formName = templateID // ID to be used when parsing mailed form submissions
  const formURL = `/${templateID}` // URL of this form
  const formLabel = 'Equipment Catalog' // used as heading for the form page
  const formSubmittedMessage = 'Your data has been submitted securely.' // Submission message

  const [isSecondMonitor, setIsSecondMonitor] = useState(false)
  const [forceShipping, setForceShipping] = useState(false)
  const [deliveryMethod, setDeliveryMethod] = useState('curbside')
  const [onBehalfOf, setOnBehalfOf] = useState('no')
  const [office, setOffice] = useState(locations.HORSHAM)
  const [formDisabled, setFormDisabled] = useState(false)
  const [formError, setFormError] = useState()
  const [formSuccess, setFormSuccess] = useState(false)
  const {
    register: registerForm,
    handleSubmit: handleFormSubmit,
    errors: formErrors,
    setValue,
    getValues,
  } = useForm({criteriaMode: 'all'})

  const mutateFormData = (formData) => { // function to do custom modifications on FormData inside the form handler
    formData.newEmployee = false

    if (formData.onBehalfOf === 'new') { // this field is removed for new people
      formData.requestChair = false
      formData.newEmployee = true // override default

      if (formData.office !== locations.FIELD) {
        formData.addressLine1 = '(HR will supply address)'
      }
    }

    return formData
  }

  const submitFormHandler = generateSubmitFormHandler({ // makes handler that accepts single formData param, while other needed things are in scope
    domain: 'SolutionCenter',
    user,
    setFormDisabled,
    setFormError,
    setFormSuccess,
    formName,
    templateID,
    confirmationTemplateID,
    saveToMongo: true,
  }, mutateFormData)

  const showShippingAddressForm = () => {
    if (onBehalfOf === 'new' && office !== locations.FIELD) {
      return false
    }

    if (deliveryMethod === 'shipping' || forceShipping) {
      return true
    }

    return false
  }

  const handleRequestSecondMonitorChange = (event) => {
    setIsSecondMonitor(event.target.checked)

    if (!isSecondMonitor) {
      setValue('requestDockingStation', true, {shouldDirty: true})
      console.info('Setting requestDockingStation')
    }
    console.info('isSecondMonitor: ', isSecondMonitor)
  }

  const handleRequestChairChange = (event) => {
    console.info('\n\nrequestChair changed to', event.target.checked)

    setForceShipping(!forceShipping)

    console.info('forceShipping: ', forceShipping)

    if (forceShipping) {
      setValue('deliveryMethod', 'shipping', {shouldDirty: true})
      console.info('Setting deliveryMethod to shipping')
    }
  }

  const handleOnBehalfOfChange = (event) => {
    setOnBehalfOf(event.target.value)

    if (event.target.value === 'new') {
      setForceShipping('yes')
      setDeliveryMethod('shipping')
      setValue('deliveryMethod', 'shipping', {shouldDirty: true})
    }
  }

  const handleOfficeChange = (event) => {
    setOffice(event.target.value)

    if (event.target.value === locations.HORSHAM) {
      let addressLine1 = getValues({nest: false}).addressLine1

      setForceShipping(false)

      if (!addressLine1 && onBehalfOf !== 'new') { // only switch to curbside if they haven't entered an address yet
        setValue('deliveryMethod', 'curbside', {shouldDirty: true})
        setDeliveryMethod('curbside')
      } else {
        setDeliveryMethod('shipping')
        setValue('deliveryMethod', 'shipping', {shouldDirty: true})
      }
    } else {
      setForceShipping(true)
      setValue('deliveryMethod', 'shipping', {shouldDirty: true})
      setDeliveryMethod('shipping')
    }
  }

  return (
    <main>
      {formError && (
        <ErrorAlert error={formError}></ErrorAlert>
      )}
      <div className="container">
        <div className="formHeader">
          <h1>{formLabel}</h1>
          <FormSuccess show={formSuccess} message={formSubmittedMessage} createAnotherURL={formURL} />
        </div>
        {!formSuccess &&
        <>
          <form
            className="form"
            onSubmit={handleFormSubmit(submitFormHandler)}
          >
            <SubmitButton label={'Place Order'} disabled={formDisabled} />

            <SubmitterInformationFieldset registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors}
              userId={user.id} userFullName={user.fullName} userEmail={user.email} />

            <Fieldset numberOfColumns={2} label={'Recipient Information'}>
              <Select name="onBehalfOf" label="Are you the intended recipient?"
                onChange={handleOnBehalfOfChange} defaultValue="no"
                options={[
                  {
                    label: 'YES, this order is for me',
                    value: 'no',
                  },
                  {
                    label: 'NO, this is for another employee',
                    value: 'yes',
                  },
                  {
                    label: 'NO, this is for a new employee',
                    value: 'new',
                  },
                ]}
                col="1" row="1" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              {onBehalfOf !== 'no' &&
              <Input name="recipientName" label="Full Name Of Recipient" validators={makeRequired()}
                col="2" row="1" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>
              }

              <Input name="managerEmail" label={'Email Address of ' + (onBehalfOf !== 'no' ? 'Recipient\'s' : 'Your') + ' Manager'} validators={makeRequired()}
                col="1" row="2" disabled={formDisabled} type={'email'}
                register={registerForm} errors={formErrors} />

              <Select name="office" label={'Where ' + (onBehalfOf !== 'no' ? 'is recipient' : 'are you') + ' based?'}
                onChange={handleOfficeChange} defaultValue="no"
                options={[
                  {
                    label: 'Horsham Home Office',
                    value: locations.HORSHAM,
                  },
                  {
                    label: 'Windsor Office',
                    value: locations.WINDSOR,
                  },
                  {
                    label: 'A Field Office',
                    value: locations.FIELD,
                  },
                ]}
                col="1" colSpan="1" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              {office === locations.FIELD &&
              <Input name="fieldOffice" label={(onBehalfOf !== 'no' ? 'Recipient\'s' : 'Your') + ' Field Office Name'} validators={makeRequired()}
                col="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>
              }

              <Input name="costCenter" label={'Cost Center to Bill (if known)'}
                col="1" disabled={formDisabled} register={registerForm} errors={formErrors} />

            </Fieldset>

            <Fieldset numberOfColumns={2} label={'Requested Items'}>
              <Checkbox name="requestDockingStation" label="Docking Station" hasIcon={true}
                col="1" colSpan="1" row="1" disabled={formDisabled} defaultChecked={isSecondMonitor}
                register={registerForm} errors={formErrors} />
              {isSecondMonitor && <GridContainer label="Note" col="2" colSpan="2" row="1" ><p>Required for a second monitor, deselect otherwise.</p></GridContainer>}

              <Checkbox name="requestFirstMonitor" label="1st Monitor" hasIcon={true}
                col="1" colSpan="1" row="2" disabled={formDisabled}
                register={registerForm} errors={formErrors} />
              <Checkbox name="requestSecondMonitor" label="2nd Monitor" hasIcon={true}
                col="2" colSpan="1" row="2" disabled={formDisabled}
                onChange={handleRequestSecondMonitorChange}
                register={registerForm} errors={formErrors} />

              <Checkbox name="requestKeyboardMouse" label="USB Keyboard and Mouse" hasIcon={true}
                col="1" colSpan="1" row="3" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              <Checkbox name="requestWebcam" label="USB Webcam" hasIcon={true}
                col="1" colSpan="1" row="4" disabled={formDisabled}
                register={registerForm} errors={formErrors} />
              <GridContainer label={'Note'} col="2" colSpan="2" row="4" ><p>For use with 2+ monitors, only.</p></GridContainer>

              <Checkbox name="requestHeadset" label="Headset" hasIcon={true}
                col="1" colSpan="1" row="5" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              <Checkbox name="requestZoomLicense" label="Zoom Pro License" hasIcon={true}
                col="1" colSpan="1" row="6" disabled={formDisabled}
                register={registerForm} errors={formErrors} />
              <GridContainer label={'Note'} col="2" colSpan="2" row="6" ><p>Needed only if you HOST meetings.</p></GridContainer>

              {onBehalfOf !== 'new' &&
              <Checkbox name="requestChair" label="Office Chair" hasIcon={true}
                col="1" colSpan="1" row="7" disabled={formDisabled}
                register={registerForm} errors={formErrors} onChange={handleRequestChairChange}/>
              }
              {onBehalfOf !== 'new' && office === locations.HORSHAM && <GridContainer label={'Note'} col="2" colSpan="2" row="7" ><p>Chairs will always be shipped.</p></GridContainer>}
            </Fieldset>

            <Fieldset numberOfColumns={4} label={'Delivery Information'} >
              <Select name="deliveryMethod" label={'Pick-up ' + (onBehalfOf !== 'new' ? '(except chairs)' : '') + ' or deliver?'}
                onChange={(event) => setDeliveryMethod(event.target.value)} defaultValue={deliveryMethod}
                options={[
                  {
                    label: 'Curbside - ' + (onBehalfOf === 'no' ? 'I' : 'They') + '\'ll pick it up',
                    value: 'curbside',
                  },
                  {
                    label: 'Shipping - deliver it to ' + (onBehalfOf === 'no' ? 'me' : 'them'),
                    value: 'shipping',
                  },
                ]}
                col="1" colSpan={2} row="1" disabled={formDisabled || office !== locations.HORSHAM || (onBehalfOf === 'new')}
                register={registerForm} errors={formErrors}/>
              { forceShipping && deliveryMethod === 'curbside' && <GridContainer col="3" colSpan="2" row="1" ><p>Your chair will still be shipped.</p></GridContainer>}
              { onBehalfOf === 'new' && office !== locations.FIELD && <GridContainer col="3" colSpan="2" row="1" ><p>HR will supply shipping information.</p></GridContainer>}

              { showShippingAddressForm() &&
                <>
                  <Input name="addressLine1" label="Street Address"
                    col="1" colSpan="4" disabled={formDisabled}
                    register={registerForm} errors={formErrors} validators={makeRequired()} />
                  <Input name="addressLine2" label="Apartment / Suite / Other Info"
                    col="1" colSpan="4" row="3"disabled={formDisabled}
                    register={registerForm} errors={formErrors} />
                  <Input name="addressCity" label="City"
                    col="1" colSpan="4" disabled={formDisabled}
                    register={registerForm} errors={formErrors} validators={makeRequired()} />
                  <Input name="addressState" label="State"
                    col="1" colSpan="2" disabled={formDisabled}
                    register={registerForm} errors={formErrors} validators={makeRequired()} />
                  <Input name="addressZip" label="Zip"
                    col="3" colSpan="2" disabled={formDisabled}
                    register={registerForm} errors={formErrors} validators={makeRequired()} />
                  <Input name="shippingPhone" label="Shipping contact Phone Number"
                    col="1" colSpan="2" disabled={formDisabled}
                    register={registerForm} errors={formErrors} validators={makeRequired()} />
                </>
              }

            </Fieldset>

            <Fieldset numberOfColumns={1} label={'Additional Information'} >
              <TextArea name="specialInstructions" label="Note for Solution Center"
                col="1" colSpan="1" row="1" textareaRows="3" disabled={formDisabled}
                register={registerForm} errors={formErrors} />
            </Fieldset>

            <SubmitButton label={'Place Order'} disabled={formDisabled} />
          </form>
        </>
        }

      </div>
    </main>
  )
}

export default EquipmentCatalog
