import React, {useState, useContext} from 'react'
import {useForm} from 'react-hook-form'
import ErrorAlert from 'components/ErrorAlert/ErrorAlert'
import * as API from 'api/api'
import {ScrollToTopButton, Fieldset, TextArea, Input, Select, CheckboxGroup} from 'f-lib'
import AuthContext from 'context/auth.context'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import FormSuccess from 'components/FormSuccess/FormSuccess'
import SubmitterInformationFieldset from 'components/SubmitterInformationFieldset/SubmitterInformationFieldset'
import {makeRequired, phoneValidator} from 'utils/fieldValidators'

import './WirelessAccess.scss'

const postitionTypes = {
  ASSISTANT: 'assistant',
  FINANCIAL_PROFESSIONAL: 'fp',
  HOME_OFFICE: 'homeoffice',
  OFFICE_STAFF: 'officestaff',
}

const WirelessAccess = () => {
  const {user} = useContext(AuthContext)
  const setupFormTemplateID = 'wireless-access' // template to be used by the Mailer Service
  const setupFormConfirmationTemplateID = 'wireless-access-confirmation'
  const setupFromAddress = 'noreply@pennmutual.com'
  const setupFormName = 'wireless-access' // ID to be used when parsing mailed form submissions
  const setupFormURL = '/wireless-access' // URL of this form
  const setupFormLabel = 'Wireless Email - Access Request Form' // used as heading for the form page
  const setupFormSubmittedMessage = 'Your data has been submitted securely.' // Submission message

  const [formDisabled, setFormDisabled] = useState(false)
  const [formError, setFormError] = useState()
  const [formSuccess, setFormSuccess] = useState(false)
  const [onBehalfOf, setOnBehalfOf] = useState('no')

  const {
    register: registerForm,
    handleSubmit: handleFormSubmit,
    errors: formErrors,
  } = useForm({criteriaMode: 'all'})

  const handler = (event) => {
    console.info(event)
    return handleFormSubmit(submitForm)(event)
  }

  const submitForm = async(formData) => {
    setFormDisabled(true)

    formData = {'_formName': setupFormName, ...formData}

    formData.submitterEmail = user.email
    formData.submitterFullname = user.fullName
    formData.submitterUserID = user.id // Hah! Thought you could just edit the DOM directly, did you?

    if (onBehalfOf === 'no') {
      formData.recipientName = user.fullName
      formData.recipientEmail = user.email
    }

    formData.additionalInformation = formData.additionalInformation.replaceAll('\n', '  ***  ')

    try {
      await API.postFormData(formData, setupFormTemplateID, setupFromAddress)
      API.postFormData(formData, setupFormConfirmationTemplateID, setupFromAddress, formData.submitterEmail)
      setFormError(null)
      setFormSuccess(true)
    } catch (error) {
      console.log('Failed to update person:', error)
      setFormError(error)
      setFormSuccess(false)
    }

    setFormDisabled(false)
  }

  return (
    <main>
      {formError && (
        <ErrorAlert error={formError}></ErrorAlert>
      )}
      <div className="container">
        <div className="formHeader">
          <h1>{setupFormLabel}</h1>
          <FormSuccess show={formSuccess} message={setupFormSubmittedMessage} createAnotherURL={setupFormURL} />
        </div>
        {!formSuccess &&
        <>
          <form
            className="form"
            onSubmit={handler}
          >
            <SubmitButton disabled={formDisabled} />

            <Fieldset numberOfColumns={2} label={'Request Information'}>
              <Select name="onBehalfOf" label="Is this for you, personally?"
                onChange={(event) => setOnBehalfOf(event.target.value)} defaultValue="no"
                options={[
                  {
                    label: 'YES, this request is for me',
                    value: 'no',
                  },
                  {
                    label: 'NO, this is for another',
                    value: 'yes',
                  },
                ]}
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              {onBehalfOf === 'yes' &&
                <>
                  <Input name="recipientName" label="Full Name Of Recipient" validators={makeRequired()}
                    col="1" colSpan={2} disabled={formDisabled}
                    register={registerForm} errors={formErrors}/>
                  <Input name="recipientEmail" label={'Email Address of Recipient'} validators={makeRequired()}
                    col="1" colSpan="2" disabled={formDisabled} type={'email'}
                    register={registerForm} errors={formErrors} />
                </>
              }

              <Input name="managerEmail" label={'Email Address of ' + (onBehalfOf === 'yes' ? 'Recipient\'s ' : 'Your') + ' Manager'} validators={makeRequired()}
                col="1" colSpan="2" disabled={formDisabled} type={'email'}
                register={registerForm} errors={formErrors} />

              {/* row 2 */}
              <Select name="positionType" label={(onBehalfOf === 'yes' ? 'Recipient\'s ' : 'Your') + ' Position'}
                defaultValue={postitionTypes.FINANCIAL_PROFESSIONAL}
                options={[
                  {
                    label: `Financial Professional`,
                    value: postitionTypes.FINANCIAL_PROFESSIONAL,
                  },
                  {
                    label: `FP Staff/Assistant`,
                    value: postitionTypes.ASSISTANT,
                  },
                  {
                    label: `Field Office Staff`,
                    value: postitionTypes.OFFICE_STAFF,
                  },
                  {
                    label: `Home Office`,
                    value: postitionTypes.HOME_OFFICE,
                  },
                ]}
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              <CheckboxGroup name={'mobileOS'} label={(onBehalfOf === 'yes' ? 'Recipient\'s ' : 'Your') + ' Phone Mobile Type'} defaultValue={'ios'}
                col={1} colSpan={2} isRadio={true} disabled={formDisabled} hasIcon={true}
                register={registerForm} errors={formErrors}
                options={[{label: 'Apple iOS', iconClasses: 'fa-apple', value: 'ios'},
                  {label: 'Android', iconClasses: 'fa-smile', value: 'android'}]}
              />
              <Input name="phoneNumber" label={(onBehalfOf === 'yes' ? 'Recipient\'s ' : 'Your') + ' Mobile Phone Number'} validators={makeRequired(phoneValidator)}
                col="1" colSpan={2} disabled={formDisabled} type={'tel'}
                register={registerForm} errors={formErrors} />

              <TextArea label={'Additional Information'} name={'additionalInformation'}
                col={1} colSpan={2}
                register={registerForm} formDisabled={formDisabled} errors={formErrors}/>

            </Fieldset>

            <SubmitterInformationFieldset registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors}
              userId={user.id} userFullName={user.fullName} userEmail={user.email} />

            <SubmitButton disabled={formDisabled} />
          </form>
        </>
        }

      </div>
      <ScrollToTopButton></ScrollToTopButton>
    </main>
  )
}

export default WirelessAccess
