import React, {useContext, useState} from 'react'
import ErrorAlert from 'components/ErrorAlert/ErrorAlert'
import FormSuccess from 'components/FormSuccess/FormSuccess'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import AuthContext from 'context/auth.context'
import {useForm} from 'react-hook-form'
import SubmitterInformationFieldset from 'components/SubmitterInformationFieldset/SubmitterInformationFieldset'
import {generateSubmitFormHandler} from 'utils/formSubmission'
import DepartmentInformationFieldset from 'components/DepartmentInformationFieldset/DepartmentInformationFieldset'
// eslint-disable-next-line no-unused-vars
import {Fieldset, Input, TextArea, CheckboxGroup, GridContainer, InputFile, Select, If} from 'f-lib'
// eslint-disable-next-line no-unused-vars
import {imageFileValidator, makeRequired} from 'utils/fieldValidators'

import './OfficeAccessRequest.scss'

const situation = {
  DEDICATED_DESK: 'has dedicated desk',
  HOTEL_SPACE: 'needs hotel space',
}

const VACCINE_STATUS = {
  YES: 'fully vaccinated',
  PARTIAL: 'partially vaccinated',
  NO: 'not vaccinated',
}

const VACCINE_PROOF = {
  CARD: 'CDC vaccination card',
  OTHER: 'Other Vaccination Record',
  LOST: 'Lost',
}

// eslint-disable-next-line no-unused-vars
const VACCINE_MAKE = {
  JANDJ: 'J&J',
  MODERNA: 'Moderna',
  PFIZER: 'Pfizer',
}

const OfficeAccessRequest = () => {
  const {user} = useContext(AuthContext)
  const templateID = 'office-access-request' // template to be used by the Mailer Service
  const formLabel = 'Office Access Request' // used as heading for the form page
  const confirmationTemplateID = `${templateID}-confirmation`
  const formName = templateID // ID to be used when parsing mailed form submissions
  const formURL = `/${templateID}` // URL of this form
  const formSubmittedMessage = 'Your data has been submitted securely.' // Submission message

  const [formDisabled, setFormDisabled] = useState(false)
  const [formError, setFormError] = useState()
  const [formSuccess, setFormSuccess] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [vaccineStatus, setVaccineStatus] = useState(VACCINE_STATUS.NO)
  // eslint-disable-next-line no-unused-vars
  const [vaccineProof, setVaccineProof] = useState(VACCINE_PROOF.CARD)
  const {
    register: registerForm,
    handleSubmit: handleFormSubmit,
    errors: formErrors,
  } = useForm({criteriaMode: 'all'})

  const submitFormHandler = generateSubmitFormHandler({ // makes handler that accepts single formData param, while other needed things are in scope
    domain: 'HumanResources',
    user,
    setFormDisabled,
    setFormError,
    setFormSuccess,
    formName,
    templateID,
    confirmationTemplateID,
    saveToMongo: true,
  }, (formData) => {
    formData.reason = formData.reason.replaceAll('\n', '  ***  ')
    formData.frequency = formData.frequency.replaceAll('\n', '  ***  ')

    return formData
  })

  return (
    <main>
      {formError && (
        <ErrorAlert error={formError}></ErrorAlert>
      )}
      <div className="container">
        <div className="formHeader">
          <h1>{formLabel}</h1>
          <FormSuccess show={formSuccess} message={formSubmittedMessage} createAnotherURL={formURL} />
        </div>
        <If condition={!formSuccess}>
          <form
            className="form"
            onSubmit={handleFormSubmit(submitFormHandler)}
          >
            <SubmitButton label={'Submit Request'} disabled={formDisabled} />

            <SubmitterInformationFieldset registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors}
              userId={user.id} userFullName={user.fullName} userEmail={user.email} />

            <DepartmentInformationFieldset registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors}/>

            <Fieldset numberOfColumns={1} label={'COVID-19 Vaccination Status'}>

              <CheckboxGroup name={'vaccinationStatus'} label={'Are you fully vaccinated (as defined by the CDC)?'} defaultValue={VACCINE_STATUS.NO}
                isRadio={true} disabled={formDisabled} hasIcon={true}
                register={registerForm} errors={formErrors}
                options={[{label: 'Yes', value: VACCINE_STATUS.YES},
                  {label: 'No', value: VACCINE_STATUS.NO}]}
              />

            </Fieldset>

            {/*

            <Fieldset numberOfColumns={2} label={'COVID-19 Vaccination Status'}>

              <CheckboxGroup name={'vaccinationStatus'} label={'What is your vaccination status (as defined by the CDC)?'} defaultValue={VACCINE_STATUS.NO}
                col="1" colSpan="2" onClick={(event) => setVaccineStatus(event.target.value)}
                isRadio={true} disabled={formDisabled} hasIcon={true}
                register={registerForm} errors={formErrors}
                options={[{label: 'Full', value: VACCINE_STATUS.YES},
                  {label: 'Partial', value: VACCINE_STATUS.PARTIAL},
                  {label: 'Unvaccinated', value: VACCINE_STATUS.NO}]}
              />

              <If condition={vaccineStatus === VACCINE_STATUS.YES}>
                <Select name="vaccinationProof" label="Choose form of vaccination proof"
                  onChange={(event) => setVaccineProof(event.target.value)} defaultValue={vaccineProof.CARD}
                  options={[
                    {
                      label: `CDC COVID-19 Vaccination Record Card`,
                      value: VACCINE_PROOF.CARD,
                    },
                    {
                      label: `Other Vaccination Record`,
                      value: VACCINE_PROOF.OTHER,
                    },
                    {
                      label: `I've lost my card`,
                      value: VACCINE_PROOF.LOST,
                    },
                  ]}
                  col="1" disabled={formDisabled}
                  register={registerForm} errors={formErrors} />

                <If condition={vaccineProof !== VACCINE_PROOF.LOST}>
                  <GridContainer col={1} colSpan={2} usePadding={false}>
                    <p>Only JPG, JPEG, PNG, and PDF files are accepted.  Files must be 8MB in size, or smaller.</p>
                  </GridContainer>
                </If>

                <If condition={vaccineProof === VACCINE_PROOF.CARD}>
                  <InputFile name="vaccineCardFront" label="Vaccination Card Front Photo" validators={makeRequired(imageFileValidator)}
                    col="1"
                    disabled={formDisabled}
                    register={registerForm} errors={formErrors}/>
                  <InputFile name="vaccineCardBack" label="Vaccination Card Back Photo" validators={makeRequired(imageFileValidator)}
                    disabled={formDisabled}
                    register={registerForm} errors={formErrors}/>
                </If>

                <If condition={vaccineProof === VACCINE_PROOF.OTHER}>
                  <InputFile name="vaccineOther" label="Photo of Vaccination Record" validators={makeRequired(imageFileValidator)}
                    col="1"
                    disabled={formDisabled}
                    register={registerForm} errors={formErrors}/>
                  <InputFile name="vaccineOther2" label="Additional Photo of Vaccination Record" validators={imageFileValidator}
                    disabled={formDisabled}
                    register={registerForm} errors={formErrors}/>
                </If>

                <If condition={vaccineProof === VACCINE_PROOF.LOST}>
                  <GridContainer col={1} colSpan={2} label="Lost Card Note">
                    <p>HR will contact you on how to obtain proof of vaccination that you can submit later.</p>
                  </GridContainer>
                </If>

                <Select name="vaccineType" label="Vaccine Type"
                  defaultValue={VACCINE_MAKE.JANDJ}
                  options={[
                    {
                      label: `Johnson & Johnson`,
                      value: VACCINE_MAKE.JANDJ,
                    },
                    {
                      label: `Moderna`,
                      value: VACCINE_MAKE.MODERNA,
                    },
                    {
                      label: `Pfizer`,
                      value: VACCINE_MAKE.PFIZER,
                    },
                  ]}
                  col="1" disabled={formDisabled}
                  register={registerForm} errors={formErrors} />
                <GridContainer>
                  <p>Type of your most recent vaccination</p>
                </GridContainer>
                <Input name="vaccineDate1" label="Vaccination Date" type="date" validators={makeRequired()}
                  col="1" disabled={formDisabled}
                  register={registerForm} errors={formErrors}/>
                <GridContainer>
                  <p>First dose</p>
                </GridContainer>
                <Input name="vaccineDate2" label="Vaccination Date 2" type="date"
                  col="1" disabled={formDisabled}
                  register={registerForm} errors={formErrors}/>
                <GridContainer>
                  <p>Second dose (if applicable)</p>
                </GridContainer>
                <Input name="vaccineBoosterDate" label="Booster Date" type="date"
                  col="1" disabled={formDisabled}
                  register={registerForm} errors={formErrors}/>
                <GridContainer>
                  <p>Most recent booster dose (if applicable)</p>
                </GridContainer>
              </If>

              <If condition={vaccineStatus !== VACCINE_STATUS.YES}>
                <GridContainer col={1} colSpan={2} label={'Please note'}>
                  <p> Before office access is approved, you are required to submit a weekly negative test result.</p>
                </GridContainer>
              </If>

            </Fieldset>

*/}

            <Fieldset numberOfColumns={3} label={'Request Details'}>

              <TextArea name="reason" label="Reason for requesting office access?" validators={makeRequired()}
                col="1" colSpan="3" textareaRows="3" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              <TextArea name="frequency" label="Days / Hours / Frequency Needed?" validators={makeRequired()}
                col="1" colSpan="3" textareaRows="3" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              <CheckboxGroup name={'desk'} label={'Which describes your situation?'} defaultValue={situation.DEDICATED_DESK}
                col={1} colSpan={3} isRadio={true} disabled={formDisabled} hasIcon={true}
                register={registerForm} errors={formErrors}
                options={[{label: 'I have a dedicated desk', value: situation.DEDICATED_DESK},
                  {label: 'I need hoteling space', value: situation.HOTEL_SPACE}]}
              />

            </Fieldset>

            <Fieldset numberOfColumns={1}>
              <GridContainer usePadding={false}>
                <p>Any updates to vaccination status will require a new form submission.</p>
                <p>All requests are subject to Human Resources approval and are not guaranteed.</p>
                <p>Please contact <a href="mailto:HR@pennmutual.com">HR@PennMutual.com</a> with any questions.</p>
              </GridContainer>
            </Fieldset>

            <SubmitButton label={'Submit Request'} disabled={formDisabled} />
          </form>
        </If>
      </div>
    </main>
  )
}

export default OfficeAccessRequest
