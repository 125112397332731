import React, {useState, useContext} from 'react'
import {useForm} from 'react-hook-form'
import ErrorAlert from 'components/ErrorAlert/ErrorAlert'
import {ScrollToTopButton, Fieldset, GridContainer, TextArea} from 'f-lib'
import AuthContext from 'context/auth.context'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import FormSuccess from 'components/FormSuccess/FormSuccess'
import BaseInformationFieldset from 'components/BaseInformationFieldset/BaseInformationFieldset'
import SubmitterInformationFieldset from 'components/SubmitterInformationFieldset/SubmitterInformationFieldset'
import {canAccessFtars} from 'utils/roles'
import {useHistory} from 'react-router-dom'
import {generateSubmitFormHandler} from 'utils/formSubmission'

const FTARFinancialProfessional = () => {
  const {user} = useContext(AuthContext)
  const history = useHistory()
  const templateID = 'ftar-fp' // template to be used by the Mailer Service
  const confirmationTemplateID = 'ftar-confirmation'
  const formName = templateID // ID to be used when parsing mailed form submissions
  const formURL = `/${templateID}` // URL of this form
  const formLabel = 'FP - Field Technology Access Request' // used as heading for the form page
  const formSubmittedMessage = 'Your data has been submitted securely.' // Submission message

  const [formDisabled, setFormDisabled] = useState(false)
  const [formError, setFormError] = useState()
  const [formSuccess, setFormSuccess] = useState(false)
  const {
    register: registerForm,
    handleSubmit: handleFormSubmit,
    errors: formErrors,
  } = useForm({criteriaMode: 'all'})

  const submitFormHandler = generateSubmitFormHandler({ // makes handler that accepts single formData param, while other needed things are in scope
    domain: 'AccountManagement',
    user,
    setFormDisabled,
    setFormError,
    setFormSuccess,
    formName,
    templateID,
    confirmationTemplateID,
    saveToMongo: true,
  }, (formData) => {
    formData.specialRequests = formData.specialRequests.replaceAll('\n', '  ***  ')

    return formData
  })

  if (user) {
    if (!canAccessFtars(user)) {
      history.push('/unauthorized')
    }
  }

  return (
    <main>
      {formError && (
        <ErrorAlert error={formError}></ErrorAlert>
      )}
      <div className="container">
        <div className="formHeader">
          <h1>{formLabel}</h1>
          <FormSuccess show={formSuccess} message={formSubmittedMessage} createAnotherURL={formURL} />
        </div>
        {!formSuccess &&
        <>
          <form
            className="form"
            onSubmit={handleFormSubmit(submitFormHandler)}
          >
            <SubmitButton disabled={formDisabled} />

            <BaseInformationFieldset askForNPN={true} personType={'Financial Professional'} title={'Financial Professional Information'} registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors} />

            <Fieldset numberOfColumns={2} label={'Requested Services'} >
              {/* row 1 */}
              <GridContainer col={1} colSpan={2} label="Directions">
                <p>For shared drive, mail aliases, or shared mailbox requests.</p>
              </GridContainer>

              {/* row 2 */}
              <TextArea label={'Extra Services'} name={'specialRequests'}
                col={1} colSpan={2} row={2}
                register={registerForm} formDisabled={formDisabled} errors={formErrors}/>

            </Fieldset>

            <SubmitterInformationFieldset registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors}
              userId={user.id} userFullName={user.fullName} userEmail={user.email} />

            <SubmitButton disabled={formDisabled} />
          </form>
        </>
        }

      </div>
      <ScrollToTopButton></ScrollToTopButton>
    </main>
  )
}

export default FTARFinancialProfessional
