import React, {useState} from 'react'
import {Fieldset, Input, GridContainer, Select, If} from 'f-lib'
import {makeRequired, nameValidator, phoneValidator} from 'utils/fieldValidators'

const staffRoles = {
  MANAGEMENT: 'management',
  SALES_MARKETING: 'salesmarketing',
  STAFF: 'staff',
}

const BaseInformationFieldset = ({mfaRequired = true, ssnRequired = true, ssnShown = true, askForNPN = false, roleInsteadOfSSN = false, personType = 'employee', title = 'Person Information', registerForm, formDisabled, formErrors}) => {
  const [newExistingEmployee, setNewExistingEmployee] = useState('new')
  const [hasPMLEmail, setHasPMLEmail] = useState(false)
  /* Custom Click/Change Handlers for conditional form fields */

  const handleHasPMLEmailChange = (event) => {
    setHasPMLEmail(event.target.value === 'yes')
  }
  const handleNewExistingEmployeeChange = (event) => {
    setNewExistingEmployee(event.target.value)
  }

  /* End Custom Click/Change Handlers */

  const ssnValidator = ssnRequired ? makeRequired() : () => {}
  const mfaValidator = mfaRequired ? makeRequired(phoneValidator) : phoneValidator

  return (
    <Fieldset numberOfColumns={4} label={title} >
      {/* row 1 */}
      <Input name="firstName" label="First Name" validators={makeRequired(nameValidator)}
        col="1" colSpan="2" disabled={formDisabled}
        register={registerForm} errors={formErrors} />
      <Input name="lastName" label="Last Name" validators={makeRequired(nameValidator)}
        col="3" colSpan="2" disabled={formDisabled}
        register={registerForm} errors={formErrors} />

      {/* row 2 */}
      <Select name="employeeType" label={`Is this a new or existing ${personType}?`}
        onChange={handleNewExistingEmployeeChange} defaultValue="new"
        options={[
          {
            label: `New ${personType}`,
            value: 'new',
          },
          {
            label: `Existing ${personType}`,
            value: 'existing',
          },
        ]}
        col="1" colSpan="2" disabled={formDisabled}
        register={registerForm} errors={formErrors} />
      {newExistingEmployee === 'new' &&
      <Input name="startDate" label="Start Date" type="date" validators={makeRequired()}
        col="3" colSpan="2" disabled={formDisabled}
        register={registerForm} errors={formErrors}/>
      }

      {/* row 3 */}
      {newExistingEmployee !== 'new' &&
      <Input name="lanID" label="Network User ID" validators={makeRequired()}
        col="1" colSpan="2" disabled={formDisabled}
        register={registerForm} errors={formErrors}/>
      }
      {newExistingEmployee === 'new' && !roleInsteadOfSSN && ssnShown &&
      <> {/* TODO: Add SSN validator */}
        <Input name="taxID" label="Social Security Number" validators={ssnValidator}
          col="1" colSpan="2" disabled={formDisabled}
          register={registerForm} errors={formErrors}/>
        <GridContainer label="Note" col="3" colSpan="2">
          <p>We'll email instructions for SSN if left blank.</p>
        </GridContainer>
      </>
      }

      <If condition={askForNPN}>
        <Input name="npn" label="NPN"
          col="1" colSpan="2" disabled={formDisabled}
          register={registerForm} errors={formErrors}/>
        <GridContainer label="Note" col="3" colSpan="2">
          <p>Please provide FP's NPN, if known.</p>
        </GridContainer>
      </If>

      {newExistingEmployee === 'new' && roleInsteadOfSSN &&
      <Select name="staffRole" label="Office Staff Role"
        defaultValue="CAS" options={[
          {
            label: 'PML Field Staff',
            value: staffRoles.STAFF,
          },
          {
            label: 'PML Field Management',
            value: staffRoles.MANAGEMENT,
          },
          {
            label: 'Sales / Marketing',
            value: staffRoles.SALES_MARKETING,
          },
        ]}
        col="1" colSpan="2" disabled={formDisabled}
        register={registerForm} errors={formErrors} />
      }

      {/* row 4 */}
      { newExistingEmployee === 'new' &&
        <>
          <Input name="mfaNumber" label="MFA Phone Number" validators={mfaValidator}
            col="1" colSpan="2" disabled={formDisabled}
            register={registerForm} errors={formErrors}/>
          <GridContainer label="Note" col="3" colSpan="2">
            <p>Phone number to use during login verification.</p>
          </GridContainer>
        </>
      }

      {/* row 4 */}
      <Input name="officeCode" label="Office Code" validators={makeRequired()}
        col="1" colSpan="2" disabled={formDisabled}
        register={registerForm} errors={formErrors}/>
      <Select name="distributionChannel" label="Distribution Channel"
        defaultValue="CAS" options={[
          {
            label: 'CAS',
            value: 'CAS',
          },
          {
            label: 'IFN',
            value: 'IFN',
          },
        ]}
        col="3" colSpan="2" disabled={formDisabled}
        register={registerForm} errors={formErrors} />

      {/* row 5 */}
      {newExistingEmployee !== 'new' &&
      <>
        <Select name="hasPMLEmail" label={`Does ${personType} have a PML/HTK email address?`}
          onChange={handleHasPMLEmailChange} defaultValue="no"
          options={[
            {
              label: 'YES, has PML/HTK email',
              value: 'yes',
            },
            {
              label: 'NO, does not have PML/HTK email',
              value: 'no',
            },
          ]}
          col="1" colSpan="2" disabled={formDisabled}
          register={registerForm} errors={formErrors} />

        {/* row 6 */}
        {hasPMLEmail &&
        <Input name="pmlEmailAddress" type="email" label="PML/HTK Email Address" validators={makeRequired()}
          col="1" colSpan="3" disabled={formDisabled}
          register={registerForm} errors={formErrors}/>
        }

      </>}

      {/* row 7 */}
      {newExistingEmployee !== 'new' &&
      <Input name="altEmailAddress" type="email" label="Alternate Email Address"
        col="1" colSpan="3" disabled={formDisabled}
        register={registerForm} errors={formErrors}/>
      }
      {newExistingEmployee === 'new' &&
      <Input name="altEmailAddress" type="email" label="Alternate Email Address" validators={makeRequired()}
        col="1" colSpan="3" disabled={formDisabled}
        register={registerForm} errors={formErrors}/>
      }
    </Fieldset>
  )
}

export default BaseInformationFieldset
