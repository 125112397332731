import React, {useEffect, useState} from 'react'
import {getSession, hasSession} from 'utils/session'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import moment from 'moment'
import * as api from 'api/api'

import EquipmentCatalog from 'views/EquipmentCatalog/EquipmentCatalog'
import FTARAssistant from 'views/FTARAssistant/FTARAssistant'
import FTARFinancialProfessional from 'views/FTARFinancialProfessional/FTARFinancialProfessional'
import FTAROfficeStaff from 'views/FTAROfficeStaff/FTAROfficeStaff'
import FTARTermination from 'views/FTARTermination/FTARTermination'
import FormChange from 'views/FormChange/FormChange'
import HTKWealthView from 'views/HTKWealthView/HTKWealthView'
import HomePage from 'views/HomePage/HomePage'
import OfficeAccessRequest from 'views/OfficeAccessRequest/OfficeAccessRequest'
import SupportIssue from 'views/SupportIssue/SupportIssue'
import TestForm from 'views/TestForm/TestForm'
import Unauthorized from 'views/Unauthorized/Unauthorized'
import VolunteerHours from 'views/VolunteerHours/VolunteerHours'
import WirelessAccess from 'views/WirelessAccess/WirelessAccess'

import {LoggedOutPage, NotFoundPage} from 'f-lib'

const Loading = () => {
  return (
    <div className="loading-message">
      <FontAwesomeIcon size="lg" icon="spinner"/>
      <span>Loading</span>
    </div>
  )
}

const startRefreshCycle = (accessTokenExpiration, startUrlFragment = '') => {
  let expirationMoment = moment(accessTokenExpiration)
  let expirationMilliseconds = moment().diff(expirationMoment, 'milliseconds')
  let refreshMoment = moment(accessTokenExpiration).subtract(10, 'minutes')
  let refreshMilliseconds = moment().diff(refreshMoment, 'milliseconds')
  let currentTime = (new Date()).getTime()

  console.info('Setting up refresh cycle; token to expire at %s', expirationMoment.format())

  if (expirationMilliseconds < 0) {
    if (refreshMilliseconds < 0) {
      refreshMilliseconds = Math.abs(refreshMilliseconds) // 15000 for 15s testing of refresh cycle
      console.info('Refresh scheduled for: %s', new Date(currentTime + refreshMilliseconds))
      setTimeout(function() {
        requestRefresh()
      }, refreshMilliseconds)
    } else {
      requestRefresh()
    }
  } else {
    startUrlFragment !== '' && console.info('Forcing login loop with startUrl from refresh setup')

    if (startUrlFragment !== '') {
      startUrlFragment = startUrlFragment + '&refresh=1'
    }

    window.location.href = '/auth/pml/corp' + startUrlFragment
  }
}

const requestRefresh = () => {
  console.info('Requesting refresh')
  api.refreshSession()
    .then(response => {
      startRefreshCycle(response.data.accessTokenExpiration)
    })
    .catch(() => {
      window.location.href = '/auth/pml/corp'
    })
}

const PrivateRoute = ({component: RequestedComponent, path, componentProps}) => {
  const [isLoading, setLoading] = useState(true)
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [isAuthorized, setAuthorized] = useState(false)

  useEffect(() => {
    let startUrl = ''

    if (path !== '/') {
      startUrl = '?startUrl=' + window.encodeURIComponent('/#' + path)
    }

    if (!hasSession()) {
      startUrl !== '' && console.info('Redirecting to login loop with startUrl')
      let url = '/auth/pml/corp' + startUrl

      window.location.href = url
    } else {
      setAuthenticated(true)
      let session = getSession()

      startRefreshCycle(session.accessTokenExpiration, startUrl)
      setAuthorized(true)
      setLoading(false)
    }
  }, [path])

  return (
    <Route
      path={path}
      render={props =>
        isLoading
          ? <Loading/>
          : (isAuthenticated && isAuthorized)
            ? <RequestedComponent {...props} {...componentProps} />
            : <Redirect to={{pathname: '/unauthorized', state: {from: props.location}}}/>
      }
    />
  )
}

const Router = () => (
  <>
    <Switch>
      <Route path="/loggedout/:code?" exact component={LoggedOutPage}/>
      <Route path="/unauthorized" exact component={Unauthorized}/>
      <Route exact path="/">
        <Redirect to="/home"/>
      </Route>
      <PrivateRoute path="/equipment-catalog" exact component={EquipmentCatalog}/>
      <PrivateRoute path="/form-change" exact component={FormChange}/>
      <PrivateRoute path="/ftar-assistant" exact component={FTARAssistant}/>
      <PrivateRoute path="/ftar-fp" exact component={FTARFinancialProfessional}/>
      <PrivateRoute path="/ftar-staff" exact component={FTAROfficeStaff}/>
      <PrivateRoute path="/ftar-termination" exact component={FTARTermination}/>
      <PrivateRoute path="/htk-wealthview" exact component={HTKWealthView}/>
      <PrivateRoute path="/office-access-request" exact component={OfficeAccessRequest}/>
      <PrivateRoute path="/volunteer-hours" exact component={VolunteerHours}/>
      <PrivateRoute path="/support-issue" exact component={SupportIssue}/>
      <PrivateRoute path="/test-form" exact component={TestForm}/>
      <PrivateRoute path="/wireless-access" exact component={WirelessAccess}/>
      <PrivateRoute path="/home" exact component={HomePage}/>
      <Route component={NotFoundPage}/>
    </Switch>
  </>
)

export default Router
