import React from 'react'

const Unauthorized = () => {
  return (
    <div className="home">
      <div className="container">
        <div className="unauthorized">
          <h1>Unauthorized</h1>
          <h2>You do not have rights to access this form</h2>
        </div>
      </div>
    </div>
  )
}

export default Unauthorized
