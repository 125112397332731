import React from 'react'
import {PageHeader} from 'f-lib'
import AuthContext from 'context/auth.context'

const Header = () => {
  return (
    <AuthContext.Consumer>
      {({user}) => (
        <PageHeader title="" name={user.fullName} showLogin={!user.fullName} showLogout={user.fullName}></PageHeader>
      )}
    </AuthContext.Consumer>
  )
}

export default Header
