import React, {useState, useContext} from 'react'
import {useForm} from 'react-hook-form'
import ErrorAlert from 'components/ErrorAlert/ErrorAlert'
import {CheckboxGroup, ScrollToTopButton, Fieldset, GridContainer, TextArea, Input, InputFile} from 'f-lib'
import AuthContext from 'context/auth.context'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import FormSuccess from 'components/FormSuccess/FormSuccess'
import SubmitterInformationFieldset from 'components/SubmitterInformationFieldset/SubmitterInformationFieldset'
import {generateSubmitFormHandler} from 'utils/formSubmission'
import {imagesDocsSpreadsheetsFileValidator, makeRequired} from '../../utils/fieldValidators'

export const SUPPORT_GROUP = {
  ACE: {
    value: 'ACE',
    label: 'ACE Stuck Case',
  },
  ALPHA: {
    value: 'LIFE',
    label: 'Life Admin',
  },
  CORE: {
    value: 'CSAT',
    label: 'Core Services',
  },
  NBA: {
    value: 'LNB',
    label: 'Life New Business',
  },
  INSIGHT: {
    value: 'WAT',
    label: 'InSight / Client Portal',
  },
  UNKNOWN: {
    value: 'UNKNOWN',
    label: 'I don\'t know',
  },
}

const SupportIssue = () => {
  const {user} = useContext(AuthContext)
  const templateID = 'support-issue' // template to be used by the Mailer Service
  const confirmationTemplateID = `${templateID}-confirmation`
  const formName = templateID // ID to be used when parsing mailed form submissions
  const formURL = `/${templateID}` // URL of this form
  const formLabel = 'Support Issue' // used as heading for the form page
  const formSubmittedMessage = 'Your issue has been submitted securely.  You should receive a confirmation shortly.' // Submission message

  const [formDisabled, setFormDisabled] = useState(false)
  const [formError, setFormError] = useState()
  const [formSuccess, setFormSuccess] = useState(false)
  const {
    register: registerForm,
    handleSubmit: handleFormSubmit,
    errors: formErrors,
  } = useForm({criteriaMode: 'all'})

  const submitFormHandler = generateSubmitFormHandler({ // makes handler that accepts single formData param, while other needed things are in scope
    domain: 'ApplicationSupport',
    user,
    setFormDisabled,
    setFormError,
    setFormSuccess,
    formName,
    templateID,
    confirmationTemplateID,
    saveToMongo: true,
  }, (formData) => {
    formData.problemSummary = formData.problemSummary.replaceAll('\n', '<br/>')
    formData.problemDescription = formData.problemDescription.replaceAll('\n', '<br/>')

    return formData
  })

  return (
    <main>
      {formError && (
        <ErrorAlert error={formError}></ErrorAlert>
      )}
      <div className="container">
        <div className="formHeader">
          <h1>{formLabel}</h1>
          <FormSuccess show={formSuccess} message={formSubmittedMessage} createAnotherURL={formURL} />
        </div>
        {!formSuccess &&
        <>
          <form
            className="form"
            onSubmit={handleFormSubmit(submitFormHandler)}
          >
            <SubmitButton disabled={formDisabled} />

            <Fieldset numberOfColumns={3} label={'Requested Changes'} >
              <GridContainer col={1} colSpan={3} label="Note">
                <p>Only use this form for production support requests that require assistance from an agile team and cannot be handled by service areas.</p>
                <p>Do not use this form for enhancement requests, which should be sent directly to the product owner.</p>
                <p>Report <u>system outages</u> and other <u>severe interruptions</u> to Solution Center.</p>
              </GridContainer>

              <Input name="problemSummary" label="Brief Problem Summary" validators={makeRequired()}
                col={1} colSpan={3} disabled={formDisabled} register={registerForm} errors={formErrors}/>

              <CheckboxGroup name="supportGroup" label="Application's Supporting Group" validators={makeRequired()}
                isRadio={true} disabled={formDisabled} hasIcon={true} register={registerForm} errors={formErrors}
                options={Object.values(SUPPORT_GROUP)} col="1" colSpan="3"
              />

              <Input name="policyNumber" label="Policy Number"
                col={1} disabled={formDisabled} register={registerForm} errors={formErrors}/>

              <GridContainer col={1} colSpan={3} label="Directions">
                <p>Please be as specific and descriptive as possible when describing your issue.</p>
              </GridContainer>

              <TextArea label="Detailed Problem Description" name="problemDescription"
                col={1} colSpan={3} validators={makeRequired()}
                register={registerForm} formDisabled={formDisabled} errors={formErrors}/>

              <GridContainer col={1} colSpan={3} label="Attachments">
                <p>Attach Word docs, Excel spreadsheets, images, or PDFs as supporting documentation. Please include URLs in any screen shots.</p>
              </GridContainer>

              <InputFile name="attachment1" label="Attachment 1" validators={imagesDocsSpreadsheetsFileValidator}
                disabled={formDisabled} register={registerForm} errors={formErrors}/>
              <InputFile name="attachment2" label="Attachment 2" validators={imagesDocsSpreadsheetsFileValidator}
                disabled={formDisabled} register={registerForm} errors={formErrors}/>
              <InputFile name="attachment3" label="Attachment 3" validators={imagesDocsSpreadsheetsFileValidator}
                disabled={formDisabled} register={registerForm} errors={formErrors}/>
            </Fieldset>

            <SubmitterInformationFieldset registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors}
              userId={user.id} userFullName={user.fullName} userEmail={user.email} />

            <SubmitButton disabled={formDisabled} />
          </form>
        </>
        }

      </div>
      <ScrollToTopButton></ScrollToTopButton>
    </main>
  )
}

export default SupportIssue
