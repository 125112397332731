import React, {useState, useContext} from 'react'
import {useForm} from 'react-hook-form'
import ErrorAlert from 'components/ErrorAlert/ErrorAlert'
import {CheckboxGroup, ScrollToTopButton, Fieldset, GridContainer, TextArea, If, Input, InputFile} from 'f-lib'
import AuthContext from 'context/auth.context'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import FormSuccess from 'components/FormSuccess/FormSuccess'
import SubmitterInformationFieldset from 'components/SubmitterInformationFieldset/SubmitterInformationFieldset'
import {generateSubmitFormHandler} from 'utils/formSubmission'
import {futureDateValidator, imagesDocsSpreadsheetsFileValidator, makeRequired} from 'utils/fieldValidators'

export const REQUEST_TYPE = {
  NEW: {
    value: 'new',
    label: 'New Form',
  },
  UPDATE: {
    value: 'update',
    label: 'Update to Form',
  },
}

export const REASON_TYPE = {
  AUDIT: {
    value: 'audit',
    label: 'Audit Change',
  },
  LEGAL: {
    value: 'legal-regulatory',
    label: 'Legal/Regulatory Update',
  },
  PRODUCT: {
    value: 'product-launch',
    label: 'Product Launch',
  },
  OTHER: {
    value: 'other',
    label: 'Other (please specify)',
  },
}

const FormChange = () => {
  const {user} = useContext(AuthContext)
  const templateID = 'form-change' // template to be used by the Mailer Service
  const confirmationTemplateID = `${templateID}-confirmation`
  const formName = templateID // ID to be used when parsing mailed form submissions
  const formURL = `/${templateID}` // URL of this form
  const formLabel = 'Form Change Request' // used as heading for the form page
  const formSubmittedMessage = 'Your request has been submitted securely.  You should receive a confirmation shortly.' // Submission message

  const [formDisabled, setFormDisabled] = useState(false)
  const [formError, setFormError] = useState()
  const [formSuccess, setFormSuccess] = useState(false)
  const [reasonType, setReasonType] = useState(null)
  const [requestType, setRequestType] = useState(null)
  const {
    register: registerForm,
    handleSubmit: handleFormSubmit,
    errors: formErrors,
  } = useForm({criteriaMode: 'all'})

  const submitFormHandler = generateSubmitFormHandler({ // makes handler that accepts single formData param, while other needed things are in scope
    domain: 'SharedServices',
    user,
    setFormDisabled,
    setFormError,
    setFormSuccess,
    formName,
    templateID,
    confirmationTemplateID,
    saveToMongo: true,
  }, (formData) => {
    formData.requestedChanges = formData.requestedChanges.replaceAll('\n', '<br/>')
    if (formData.reasonDetails) {
      formData.reasonDetails = formData.reasonDetails.replaceAll('\n', '<br/>')
    }

    return formData
  })

  return (
    <main>
      {formError && (
        <ErrorAlert error={formError}></ErrorAlert>
      )}
      <div className="container">
        <div className="formHeader">
          <h1>{formLabel}</h1>
          <FormSuccess show={formSuccess} message={formSubmittedMessage} createAnotherURL={formURL} />
        </div>
        {!formSuccess &&
        <>
          <form
            className="form"
            onSubmit={handleFormSubmit(submitFormHandler)}
          >
            <SubmitButton disabled={formDisabled} />

            <Fieldset numberOfColumns={2} label={'Request Details'} >
              <Input name="department" label="Your Department" validators={makeRequired()}
                disabled={formDisabled} register={registerForm} errors={formErrors}/>
              <CheckboxGroup name="requestType" label="Request Type"
                isRadio={true} disabled={formDisabled} hasIcon={true} register={registerForm} errors={formErrors}
                col="1" colSpan="2" options={Object.values(REQUEST_TYPE)} defaultValue={REQUEST_TYPE.NEW.value}
                onClick={(event) => setRequestType(event.target.value)}/>
              <Input name="formName" label="Form Name" validators={makeRequired()}
                disabled={formDisabled} register={registerForm} errors={formErrors}/>
              <Input name="formNumber" label="Form/Item Number" validators={requestType === REQUEST_TYPE.UPDATE.value ? makeRequired() : {}}
                disabled={formDisabled} register={registerForm} errors={formErrors}/>
              <Input name="deliveryDate" label="Requested Delivery Date" type="date" validators={makeRequired(futureDateValidator)}
                disabled={formDisabled} register={registerForm} errors={formErrors}/>
              <CheckboxGroup name="reasonType" label="Reason for Change" validators={makeRequired()}
                isRadio={true} disabled={formDisabled} hasIcon={true} register={registerForm} errors={formErrors}
                options={Object.values(REASON_TYPE)} onClick={(event) => setReasonType(event.target.value)}
                col="1" colSpan="2"
              />

              <If condition={reasonType === REASON_TYPE.OTHER.value}>
                <TextArea label="Explanation of Reason" name="reasonDetails"
                  col={1} colSpan={2} validators={makeRequired()}
                  register={registerForm} formDisabled={formDisabled} errors={formErrors}/>
              </If>

            </Fieldset>
            <Fieldset numberOfColumns={3} label={'Requested Changes'} >
              <GridContainer col={1} colSpan={3} label="Directions">
                <p>Please be as specific and descriptive as possible.</p>
              </GridContainer>

              <TextArea label="Changes to be made" name="requestedChanges"
                col={1} colSpan={3} validators={makeRequired()}
                register={registerForm} formDisabled={formDisabled} errors={formErrors}/>

              <GridContainer col={1} colSpan={3} label="Examples">
                <p>Attach Word docs, Excel spreadsheets, photos, or PDFs of marked up examples of changes, if any.</p>
              </GridContainer>

              <InputFile name="example1" label="Example 1" validators={imagesDocsSpreadsheetsFileValidator}
                disabled={formDisabled} register={registerForm} errors={formErrors}/>
              <InputFile name="example2" label="Example 2" validators={imagesDocsSpreadsheetsFileValidator}
                disabled={formDisabled} register={registerForm} errors={formErrors}/>
              <InputFile name="example3" label="Example 3" validators={imagesDocsSpreadsheetsFileValidator}
                disabled={formDisabled} register={registerForm} errors={formErrors}/>
            </Fieldset>

            <SubmitterInformationFieldset registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors}
              userId={user.id} userFullName={user.fullName} userEmail={user.email} />

            <SubmitButton disabled={formDisabled} />
          </form>
        </>
        }

      </div>
      <ScrollToTopButton></ScrollToTopButton>
    </main>
  )
}

export default FormChange
