import request from 'utils/request'
import axios from 'axios'

export const uploadFile = (formData) => {
  return request.post('/file/upload', formData, {headers: {'Content-Type': 'multipart/form-data'}})
}

/*
POSTs form data to Mongo endpoint for saving
 */
export const saveToMongo = (formName, formData) => {
/*
  const payload = {
    formName,
    formData,
  }
*/

  return request.post(`/submissions`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
}

/*
POSTs form data to Mailer Service
 */
export const postFormData = (formData, templateName, toAddress) => {
  const payload = {
    fromAddress: 'noreply@pennmutual.com',
    dataMap: formData,
  }

  if (toAddress) {
    payload.toList = [toAddress]
  }

  return request.post(`/proxy/mailer/${templateName}/`, payload)
}

/*
Updates supplied person (does not persist with sample JSON data server)
 */
export const updatePerson = (person) => {
  window.alert('PUT person update:' + JSON.stringify(person))
  return request.put(`/proxy/json/people/${person.id}/`, person)
}

/*
Causes server to refresh the user's OAuth token
 */
export const refreshSession = () => {
  return axios.get(`/auth/pml/refresh`)
}
