import React from 'react'
import {HashRouter} from 'react-router-dom'
import Router from 'Router'
import {AppProvider} from 'context/app.context'
import AuthProvider from 'context/auth.provider'
import {hasSession, getSession} from 'utils/session'
import {ToastContainer} from 'react-toastify'
import {FLibBaseStyles, ScrollToTopOnRouteChange} from 'f-lib'
import 'react-toastify/dist/ReactToastify.min.css'

import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'

import 'utils/icons'
import 'react-perfect-scrollbar/dist/css/styles.css'

const App = () => {
  let session
  let fullName, id, email, isAdvisor, isAssistant, isManager, isOfficeStaff, isHomeOffice

  if (hasSession()) {
    session = getSession()
    fullName = session && session.name
    id = session && session.loggedInAs
    email = session && session.email
    isHomeOffice = session && session.isHomeOffice
    isAssistant = session && session.isAssistant
    isAdvisor = session && session.isAdvisor
    isManager = session && session.isManager
    isOfficeStaff = session && session.isOfficeStaff
  }

  return (
    <AppProvider>
      <AuthProvider
        value={{
          id,
          fullName,
          email,
          isHomeOffice,
          isAssistant,
          isAdvisor,
          isManager,
          isOfficeStaff,
        }}
      >
        <FLibBaseStyles>
          <div className="app">
            <HashRouter>
              <Header />
              <Router />
              <Footer />
              <ScrollToTopOnRouteChange/>
              <ToastContainer />
            </HashRouter>
          </div>
        </FLibBaseStyles>
      </AuthProvider>
    </AppProvider>
  )
}

export default App
