import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faEnvelope,
  faKey,
  faFileContract,
  faUser,
  faUserFriends,
  faStoreAlt,
  faBriefcase,
  faFile,
  faUserCircle,
  faPlus,
  faTrashAlt,
  faFileAlt,
  faTh,
  faThList,
  faSpinner,
  faTimes,
  faEye,
  faEllipsisV,
  faExclamationCircle,
  faLongArrowAltUp,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faEnvelope,
  faKey,
  faFileContract,
  faUser,
  faUserFriends,
  faStoreAlt,
  faBriefcase,
  faFile,
  faUserCircle,
  faPlus,
  faTrashAlt,
  faFileAlt,
  faTh,
  faThList,
  faSpinner,
  faTimes,
  faEye,
  faEllipsisV,
  faExclamationCircle,
  faLongArrowAltUp,
)
