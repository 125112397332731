import React from 'react'
import {Button} from 'f-lib'

const FormSuccess = ({show, message, createAnotherURL}) => {
  if (show) {
    return (
      <>
        <h2>{message}</h2>
        <h2>You may close this window or <Button href={createAnotherURL} outlineDark onClick={() => { window.location.reload() }}>Create another</Button> submission</h2>
      </>
    )
  }

  return null
}

export default FormSuccess
