import React, {createContext, useState} from 'react'

const AppContext = createContext()

export const AppProvider = (props) => {
  const [someProp, setSomeProp] = useState('Pony')

  return (
    <AppContext.Provider
      value={
        {someProp, setSomeProp}
      }
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContext
