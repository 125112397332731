import React from 'react'
import {Fieldset, Input} from 'f-lib'
import {makeRequired} from 'utils/fieldValidators'

/***
 *
 * @param registerForm
 * @param formDisabled
 * @param formErrors
 * @returns {JSX.Element}
 * @constructor
 */
const DepartmentInformationFieldset = ({registerForm, formDisabled, formErrors}) => {
  return (
    <Fieldset numberOfColumns={2} label={'Department Information'}>

      <Input name="department" label={'Your Department or Field Office Name'} validators={makeRequired()}
        col="1" colSpan="2" disabled={formDisabled}
        register={registerForm} errors={formErrors} />
      <Input name="managerName" label={'Your Leader\'s Name'} validators={makeRequired()}
        col="1" disabled={formDisabled}
        register={registerForm} errors={formErrors} />
      <Input name="managerEmail" label={'Your Leader\'s Email Address'} validators={makeRequired()}
        col="2" disabled={formDisabled} type={'email'}
        register={registerForm} errors={formErrors} />

    </Fieldset>
  )
}

export default DepartmentInformationFieldset
