import React from 'react'
import {Fieldset, Input} from 'f-lib'
import {makeRequired} from 'utils/fieldValidators'

const SubmitterInformationFieldset = ({userFullName, userId, userEmail, registerForm, formErrors}) => {
  return (
    <Fieldset numberOfColumns={2} label={'Submitter\'s Information'} >
      {/* row 1 */}
      <Input name="submitterFullName" label="Your Full Name" defaultValue={userFullName}
        col="1" row="1" disabled={true}
        register={registerForm} errors={formErrors} />
      <Input name="submitterUserID" label="Your User ID" defaultValue={userId}
        col="2" row="1" disabled={true}
        register={registerForm} errors={formErrors}/>

      {/* row 1 */}
      <Input name="submitterEmail" label="Your Email Address" defaultValue={userEmail}
        col="1" colSpan="2" row="2" disabled={true} validators={makeRequired()}
        type="text" register={registerForm} errors={formErrors} />

    </Fieldset>
  )
}

export default SubmitterInformationFieldset
