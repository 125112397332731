import React, {useState, useContext} from 'react'
import {useForm} from 'react-hook-form'
import ErrorAlert from 'components/ErrorAlert/ErrorAlert'
import {ScrollToTopButton, Fieldset, GridContainer, TextArea, Input, Select} from 'f-lib'
import AuthContext from 'context/auth.context'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import FormSuccess from 'components/FormSuccess/FormSuccess'
import SubmitterInformationFieldset from 'components/SubmitterInformationFieldset/SubmitterInformationFieldset'

import './FTARTermination.scss'
import {makeRequired, nameValidator, lastFourValidator, userIDValidator, officeCodeValidator} from 'utils/fieldValidators'
import {canAccessFtars} from 'utils/roles'
import {useHistory} from 'react-router-dom'
import {generateSubmitFormHandler} from 'utils/formSubmission'

const terminationTypes = {
  IMMEDIATE: 'immediate',
  END_OF_DAY: 'eod',
  OTHER: 'other',
}

const postitionTypes = {
  FINANCIAL_PROFESSIONAL: 'fp',
  ASSISTANT: 'assistant',
  OFFICE_STAFF: 'officestaff',
}

const FTARTermination = () => {
  const {user} = useContext(AuthContext)
  const history = useHistory()
  const templateID = 'ftar-termination' // template to be used by the Mailer Service
  const confirmationTemplateID = 'ftar-confirmation'
  const formName = templateID // ID to be used when parsing mailed form submissions
  const formURL = `/${templateID}` // URL of this form
  const formLabel = 'Field Technology Access Request Termination' // used as heading for the form page
  const formSubmittedMessage = 'Your data has been submitted securely.' // Submission message

  const [formDisabled, setFormDisabled] = useState(false)
  const [formError, setFormError] = useState()
  const [formSuccess, setFormSuccess] = useState(false)
  const [terminationType, setTerminationType] = useState('immediate')

  const {
    register: registerForm,
    handleSubmit: handleFormSubmit,
    errors: formErrors,
  } = useForm({criteriaMode: 'all'})

  const submitFormHandler = generateSubmitFormHandler({ // makes handler that accepts single formData param, while other needed things are in scope
    domain: 'AccountManagement',
    user,
    setFormDisabled,
    setFormError,
    setFormSuccess,
    formName,
    templateID,
    confirmationTemplateID,
    saveToMongo: true,
  }, (formData) => {
    formData.exceptions = formData.exceptions.replaceAll('\n', '  ***  ')

    return formData
  })

  if (user) {
    if (!canAccessFtars(user)) {
      history.push('/unauthorized')
    }
  }

  return (
    <main>
      {formError && (
        <ErrorAlert error={formError}></ErrorAlert>
      )}
      <div className="container">
        <div className="formHeader">
          <h1>{formLabel}</h1>
          <FormSuccess show={formSuccess} message={formSubmittedMessage} createAnotherURL={formURL} />
        </div>
        {!formSuccess &&
        <>
          <form
            className="form"
            onSubmit={handleFormSubmit(submitFormHandler)}
          >
            <SubmitButton disabled={formDisabled} />

            <Fieldset numberOfColumns={4} label="Terminated User Information" >
              {/* row 1 */}
              <Input name="firstName" label="First Name" validators={makeRequired(nameValidator)}
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors} />
              <Input name="lastName" label="Last Name" validators={makeRequired(nameValidator)}
                col="3" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              {/* row 2 */}
              <Select name="positionType" label={`Which type of user is being terminated?`}
                defaultValue={postitionTypes.FINANCIAL_PROFESSIONAL}
                options={[
                  {
                    label: `Financial Professional`,
                    value: postitionTypes.FINANCIAL_PROFESSIONAL,
                  },
                  {
                    label: `FP Staff`,
                    value: postitionTypes.ASSISTANT,
                  },
                  {
                    label: `Field Office Staff`,
                    value: postitionTypes.OFFICE_STAFF,
                  },
                ]}
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors} />

              {/* row 3 */}
              <Select name="terminationType" label={`When should termination occur?`}
                defaultValue="immediate"
                onChange={(event) => { setTerminationType(event.target.value) }}
                options={[
                  {
                    label: `Immediately`,
                    value: terminationTypes.IMMEDIATE,
                  },
                  {
                    label: `End of Specified Day`,
                    value: terminationTypes.END_OF_DAY,
                  },
                  {
                    label: `Other`,
                    value: terminationTypes.OTHER,
                  },
                ]}
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors} />
              {terminationType !== terminationTypes.IMMEDIATE &&
                <Input name="terminationDate" label="Termination Date" type="date" validators={makeRequired()}
                  col="3" colSpan="2" disabled={formDisabled}
                  register={registerForm} errors={formErrors}/>
              }
              {terminationType === terminationTypes.OTHER &&
                <Input name="terminationOther" label="Termination Instructions" validators={makeRequired()}
                  col="1" colSpan="4" disabled={formDisabled}
                  register={registerForm} errors={formErrors}/>
              }

              {/* row 4 */}
              <Input name="lanID" label="Network User ID" validators={makeRequired(userIDValidator)}
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>
              <Input name="last4" label="Last 4 Digits of SSN" validators={makeRequired(lastFourValidator)}
                col="3" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>

              {/* row 5 */}
              <Input name="officeCode" label="Office Code" validators={makeRequired(officeCodeValidator)}
                col="1" colSpan="2" disabled={formDisabled}
                register={registerForm} errors={formErrors}/>

            </Fieldset>

            <Fieldset numberOfColumns={2} label={'Exception Request'} >
              {/* row 1 */}
              <GridContainer col={1} colSpan={2} label="Directions">
                <p>Use this space for Auto-Responder messages and other special requests.</p>
              </GridContainer>

              {/* row 2 */}
              <TextArea label={'Requested Exceptions'} name={'exceptions'}
                col={1} colSpan={2}
                register={registerForm} formDisabled={formDisabled} errors={formErrors}/>

            </Fieldset>

            <SubmitterInformationFieldset registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors}
              userId={user.id} userFullName={user.fullName} userEmail={user.email} />

            <SubmitButton disabled={formDisabled} />
          </form>
        </>
        }

      </div>
      <ScrollToTopButton></ScrollToTopButton>
    </main>
  )
}

export default FTARTermination
