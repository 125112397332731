import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button} from 'f-lib'
import {faPaperPlane} from '@fortawesome/free-regular-svg-icons'

const SubmitButton = ({disabled, label, onClick = () => {}}) => {
  return (
    <div className="submitButton">
      <Button className="submitButton-actionButton" outlineDark onClick={onClick} disabled={disabled} role="submit">
        <i className="submitButton-actionButtonIcon">
          <FontAwesomeIcon icon={faPaperPlane}/>
        </i>
        {label || 'Submit'}
      </Button>
    </div>
  )
}

export default SubmitButton
