import React, {useState, useContext} from 'react'
import {useForm} from 'react-hook-form'
import ErrorAlert from 'components/ErrorAlert/ErrorAlert'
import {If, ScrollToTopButton, Fieldset, CheckboxGroup, Input, GridContainer, Checkbox} from 'f-lib'
import AuthContext from 'context/auth.context'
import SubmitButton from 'components/SubmitButton/SubmitButton'
import FormSuccess from 'components/FormSuccess/FormSuccess'
import SubmitterInformationFieldset from 'components/SubmitterInformationFieldset/SubmitterInformationFieldset'
import {generateSubmitFormHandler} from 'utils/formSubmission'
import {makeRequired, nameValidator, userIDValidator, emailValidator, noSpacesValidator} from 'utils/fieldValidators'
import * as API from '../../api/api'

export const REQUEST_TYPE = {
  REP: {
    value: 'new_rep',
    label: 'New HTK Rep',
  },
  ASSISTANT: {
    value: 'new_assistant',
    label: 'New Assistant',
  },
  UPGRADE: {
    value: 'upgrade',
    label: 'Upgrade to Performance',
  },
  INTEGRATION: {
    value: 'integration',
    label: 'New Integration',
  },
}

const HTKWealthView = () => {
  const {user} = useContext(AuthContext)
  const templateID = 'htk-wealthview' // template to be used by the Mailer Service
  const confirmationTemplateID = 'htk-wealthview-confirmation'
  const formName = templateID // ID to be used when parsing mailed form submissions
  const formURL = `/${templateID}` // URL of this form
  const formLabel = 'HTK WealthView Request Form' // used as heading for the form page
  const formSubmittedMessage = 'Your request has been submitted securely.' // Submission message

  const [requestRep, setRequestRep] = useState(false)
  const [requestAssistant, setRequestAssistant] = useState(false)
  const [requestUpgrade, setRequestUpgrade] = useState(false)
  const [requestIntegration, setRequestIntegration] = useState(false)

  const [formDisabled, setFormDisabled] = useState(false)
  const [formError, setFormError] = useState()
  const [formSuccess, setFormSuccess] = useState(false)

  const {
    errors: formErrors,
    handleSubmit: handleFormSubmit,
    register: registerForm,
    watch,
  } = useForm({criteriaMode: 'all'})

  const requestTypeMutator = (data) => {
    if (data.requestType.length) {
      for (let i of data.requestType) {
        data['requestType_' + i] = 'Yes'
      }
    }
    console.log(data)
    return data
  }

  const submitFormHandlerHTK = async(formData) => {
    // eslint-disable-next-line no-unused-vars
    const handler1 = generateSubmitFormHandler({ // makes handler that accepts single formData param, while other needed things are in scope
      domain: 'AccountManagement',
      user,
      setFormDisabled,
      setFormError,
      setFormSuccess,
      formName,
      templateID,
      confirmationTemplateID,
      saveToMongo: true,
    })

    const handler2 = generateSubmitFormHandler({ // makes handler that accepts single formData param, while other needed things are in scope
      domain: 'AccountManagement',
      user,
      setFormDisabled,
      setFormError,
      setFormSuccess,
      formName,
      templateID: templateID + '-jira',
      confirmationTemplateID: null,
      saveToMongo: false,
    })

    const mutatedData = requestTypeMutator(formData)

    await handler1(mutatedData) // HTK wants to see all requests

    if (requestRep || requestAssistant || requestUpgrade) { // JIRA only handles these
      await handler2(mutatedData)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const submitFormHandlerJIRA = async(formData) => { // This second handler only sends email to HTK - it does not do much else
    let errorState = false

    setFormDisabled(true)

    formData = {'_formName': formName, 'domain': 'AccountManagement', ...formData}

    formData.submitterEmail = user.email
    formData.submitterFullName = user.fullName
    formData.submitterUserID = user.id // Hah! Thought you could just edit the DOM directly, did you?

    try {
      await API.postFormData(formData, templateID + '-jira')
      setFormError(null)
      setFormSuccess(true)
    } catch (error) {
      errorState = true
      setFormError(error)
      setFormSuccess(false)
      console.error('Failed to email htk-wealthview-jira data: ', error)
    }

    if (!errorState && confirmationTemplateID) {
      try {
        API.postFormData(formData, confirmationTemplateID, formData.submitterEmail)
      } catch (error) {
        console.error('Failed to send confirmation email: ', error)
      }
    }

    setFormDisabled(false)
    window.scrollTo(0, 0)
  }

  const watchAcknowledgement = watch('acknowledgement', false)
  const watchEmoney = watch('emoney', false)
  const watchAdvisorEngine = watch('advisorengine', false)
  const watchMorningstar = watch('morningstar', false)
  const watchMoneyGuide = watch('moneyguide', false)
  const watchRedtail = watch('redtail', false)
  const watchRightCapital = watch('rightcapital', false)
  const watchRiskalyze = watch('riskalyze', false)
  const watchSmartOffice = watch('smartoffice', false)
  const watchWealthbox = watch('wealthbox', false)

  const updateRequestType = (value) => {
    switch (value) {
      case REQUEST_TYPE.REP.value:
        setRequestRep(!requestRep)
        break
      case REQUEST_TYPE.ASSISTANT.value:
        setRequestAssistant(!requestAssistant)
        break
      case REQUEST_TYPE.UPGRADE.value:
        setRequestUpgrade(!requestUpgrade)
        break
      case REQUEST_TYPE.INTEGRATION.value:
        setRequestIntegration(!requestIntegration)
        break
    }
  }

  return (
    <main>
      {formError && (
        <ErrorAlert error={formError}></ErrorAlert>
      )}
      <div className="container">
        <div className="formHeader" style={{marginBottom: 10}}>
          <h1>{formLabel}</h1>
          <FormSuccess show={formSuccess} message={formSubmittedMessage} createAnotherURL={formURL} />
        </div>
        {!formSuccess &&
        <>
          <form
            className="form"
            onSubmit={handleFormSubmit(submitFormHandlerHTK)}
          >
            <SubmitterInformationFieldset registerForm={registerForm} formDisabled={formDisabled} formErrors={formErrors}
              userId={user.id} userFullName={user.fullName} userEmail={user.email} />

            <Fieldset numberOfColumns={2} label={'Request Type'} >
              <CheckboxGroup name="requestType" label="Request Type" hasIcon={true} validators={makeRequired()}
                isRadio={false} disabled={formDisabled} register={registerForm} errors={formErrors}
                col="1" colSpan="2" options={Object.values(REQUEST_TYPE)}
                onClick={(event) => updateRequestType(event.target.value)}/>
            </Fieldset>

            <If condition={requestUpgrade || requestRep}>
              <Fieldset numberOfColumns={1} label={'Package Information'}>
                <If condition={requestUpgrade}>
                  <GridContainer usePadding={false}><p>Upgrading to WealthView Performance package will incur an additional monthly charge of $100.</p></GridContainer>
                </If>
                <If condition={!requestUpgrade && requestRep}>
                  <GridContainer usePadding={false}><p>WealthView Basic package is included at no cost.</p></GridContainer>
                </If>
              </Fieldset>
            </If>

            <If condition={requestAssistant}>
              <Fieldset numberOfColumns={2} label={'Assistant Info'}>
                <Input name="assistantName" label="Assistant's Full Name" validators={makeRequired(nameValidator)}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />

                <Input name="assistantEmail" label="Assistant's Email Address" validators={makeRequired(emailValidator)}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />

                <Input name="assistantUserID" label="Assistant's PML User ID" validators={makeRequired(userIDValidator)}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />
              </Fieldset>
            </If>

            <If condition={requestIntegration}>
              <Fieldset numberOfColumns={2} label={'Integrations'}>
                <Checkbox name="emoney" value={true} label="eMoney" hasIcon={true} col={1}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />
                <If condition={watchEmoney}>
                  <Input name="emoneyUserID" label="eMoney User ID" validators={makeRequired(noSpacesValidator)}
                    disabled={formDisabled} register={registerForm} errors={formErrors} />
                </If>

                <Checkbox name="advisorengine" value={true} label="Advisor Engine (Junxure)" hasIcon={true} col={1}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />
                <If condition={watchAdvisorEngine}>
                  <Input name="advisorengineUserID" label="Advisor Engine User ID" validators={makeRequired(noSpacesValidator)}
                    disabled={formDisabled} register={registerForm} errors={formErrors} />
                </If>

                <Checkbox name="morningstar" value={true} label="Morningstar AWS" hasIcon={true} col={1}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />
                <If condition={watchMorningstar}>
                  <Input name="morningstarUserID" label="Morningstar AWS User ID" validators={makeRequired(noSpacesValidator)}
                    disabled={formDisabled} register={registerForm} errors={formErrors} />
                </If>

                <Checkbox name="moneyguide" value={true} label="MoneyGuide" hasIcon={true} col={1}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />
                <If condition={watchMoneyGuide}>
                  <Input name="moneyguideUserID" label="MoneyGuide User ID" validators={makeRequired(noSpacesValidator)}
                    disabled={formDisabled} register={registerForm} errors={formErrors} />
                </If>

                <Checkbox name="redtail" value={true} label="Redtail" hasIcon={true} col={1}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />
                <If condition={watchRedtail}>
                  <Input name="redtailUserID" label="Redtail User ID" validators={makeRequired(noSpacesValidator)}
                    disabled={formDisabled} register={registerForm} errors={formErrors} />
                </If>

                <Checkbox name="rightcapital" value={true} label="Right Capital" hasIcon={true} col={1}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />
                <If condition={watchRightCapital}>
                  <Input name="rightcapitalUserID" label="Right Capital User ID" validators={makeRequired(noSpacesValidator)}
                    disabled={formDisabled} register={registerForm} errors={formErrors} />
                </If>

                <Checkbox name="riskalyze" value={true} label="Riskalyze" hasIcon={true} col={1}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />
                <If condition={watchRiskalyze}>
                  <Input name="riskalyzeUserID" label="Riskalyze User ID" validators={makeRequired(noSpacesValidator)}
                    disabled={formDisabled} register={registerForm} errors={formErrors} />
                </If>

                <Checkbox name="smartoffice" value={true} label="SmartOffice (EBIX)" hasIcon={true} col={1}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />
                <If condition={watchSmartOffice}>
                  <Input name="smartofficeUserID" label="SmartOffice User ID" validators={makeRequired(noSpacesValidator)}
                    disabled={formDisabled} register={registerForm} errors={formErrors} />
                </If>

                <Checkbox name="wealthbox" value={true} label="Wealthbox" hasIcon={true} col={1}
                  disabled={formDisabled} register={registerForm} errors={formErrors} />
                <If condition={watchWealthbox}>
                  <Input name="wealthboxUserID" label="Wealthbox User ID" validators={makeRequired(noSpacesValidator)}
                    disabled={formDisabled} register={registerForm} errors={formErrors} />
                </If>

                <If condition={!watchEmoney && !watchAdvisorEngine && !watchRiskalyze && !watchRightCapital && !watchMoneyGuide && !watchRedtail && !watchMorningstar && !watchSmartOffice && !watchWealthbox}>
                  <p className="checkboxGroup-error">at least one integration option must be selected</p>
                </If>
              </Fieldset>
            </If>

            <Fieldset numberOfColumns={3} label={'Acknowledgement'}>
              <GridContainer usePadding={false} col={1} colSpan={3}>
                <p>By completing this form, I agree to the following: I understand that I must follow
                all HTK policies and procedures for using WealthView and providing reports and/or access to the system to clients. I understand that the basic package is included with my affiliation as an HTK financial professional; I will be charged an additional $100/month when selecting the Performance Upgrade.</p>
                <p>Requests are typically processed within 5 business days.</p>
              </GridContainer>
              <Checkbox name="acknowledgement" value={true} label="I understand and agree to the above statement" hasIcon={true} col={3} validators={makeRequired()}
                disabled={formDisabled} register={registerForm} errors={formErrors} />
            </Fieldset>

            <SubmitButton disabled={formDisabled || !watchAcknowledgement} />
          </form>
        </>
        }

      </div>
      <ScrollToTopButton></ScrollToTopButton>
    </main>
  )
}

export default HTKWealthView
