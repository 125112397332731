import React from 'react'

const HomePage = () => {
  return (
    <>
      <div className="home">
        <div className="container">
          <h1>FormHost</h1>
          <h2>This application does not provide a list of available forms.</h2>
        </div>
      </div>
    </>
  )
}

export default HomePage
